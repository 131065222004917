#project-detail {
	height: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	header {
		padding: $u*1 $u*1.5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: $u*6;
		// background: $background;
		z-index: 100;
		opacity: 1;
		transition: opacity 0.3s ease-in-out 0.3s;
		h2 {
			line-height: $u*2.5;
			font-size: $u*2;
			font-weight: 700;
			text-transform: none;
		}
		p {
			padding-left: 0;
			//font-size: $u*2;
			float: none;
			text-align: center;
			font-weight: 300;
		}
		nav {
			display: flex;
			align-items: center;
			button {
				display: block;
				height: 100%;
				appearance: none;
				background: transparent;
				border: none;
				outline: none; 
				color: $light-gray;
				padding: 0;
				width: $u*4;
				height: $u*4;

				transition: opacity .8s ease-in-out;
				&:hover {
					cursor: pointer;
					color: $highlight;
				}
				&.hide {
					opacity: 0;
					transition: opacity .2s ease-in-out;
					cursor: auto;
				}
				&.show-description {
					margin: 0;
					text-align: center;
					font-size: 0;
					svg {
						transform: scale(1.1);
						pointer-events: none;
					}
					
					// transform: rotate(270deg);
				}

				svg {
					display: block;
					position: relative;
					width: 100%;
					height: 100%;
				}
			}
			span {
				color: $gray;
				display: inline-block;
				vertical-align: middle;
				line-height: $u*3;
				font-size: $u*2.2;
				font-weight: 400;
				padding: 0 $u*0.5;
				b {
					font-weight: 700;
					color: darken($gray, 5%);
				}
			}
			margin-right: -$u*0.5;
		}
		// button,
		a {
			// display: inline-block;
			border: none;
			background: transparent;
			color: $light-gray;
			font-size: $u*2.6;
			line-height: $u*3;
			min-width: $u*2;
			padding: 0;
			margin: 0;
			margin-top: -5px;
			text-align: center;
			vertical-align: middle;
			outline: none; 
			transition: opacity .8s ease-in-out;
			font-weight: 100;
			&:hover {
				cursor: pointer;
				color: $highlight;
			}
			&.hide {
				opacity: 0;
				transition: opacity .2s ease-in-out;
				cursor: auto;
			}
			&.show-description {
				margin: 0;
				text-align: center;
				font-size: 0;
				transform: rotate(270deg);
			}
		}
	}
	.images {
		display: block;
		overflow: hidden;
		position: relative;
		aspect-ratio: 3 / 2;
		ul {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			&:after {
				display: block;
				position: relative;
				content: '';
				clear: both;
			}
		}
		li {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			z-index: 5;
			transition: opacity 1s ease-in-out 0.3s;
			&.show {
				opacity: 1;
				z-index: 6;
				transition: opacity 1s ease-in-out;
			}
		}
		li > * {
			display: block;
			position: relative;
			height: 100%;
			background-color: $xlight-gray;
		}
		img {
			display: block;
			height: 100%;
			object-fit: contain;
		}
		button {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 20%;
			z-index: 10;
			border: none;
			outline: none;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			tap-highlight-color: rgba(0,0,0,0);
			cursor: pointer;
			opacity: 0;
			font-size: 0;
			&.previous-image {
				left: 0;
			}
			&.next-image {
				right: 0;
			}
		}
	}
	.description {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -$u*12;
		background: rgba(255,255,255,0.93);
		padding: $u*2 $u*2 $u*7;
		max-height: 0;
		overflow: hidden;
		transition: opacity 0.3s ease-in-out, max-height 0.5s ease-in-out 0.2s, bottom 0.5s ease-in-out 0.2s;
		z-index: 200;
		overflow-y: auto;
		h2 {
			text-transform: none;
			font-weight: 700;
			font-size: $u*1.8;
		}
		h3 {
			text-transform: none;
			color: $light-gray;
			margin-bottom: $u*2;
			font-style: italic;
		}
		.hide-description {
			position: absolute;
			top: $u*1;
			right: $u*1.5;
			border: none;
			background: transparent;
			color: $gray;
			font-size: $u*4;
			line-height: $u*3;
			height: $u*3;
			outline: none;
			cursor: pointer;
			padding: 0;
			&:hover {
				color: $highlight;
			}
		}
		& > div > * {
			margin: $u 0;
			max-width: 600px;
			margin-right: auto;
		}
		p {
			color: $gray;
		}
	}
	&.show {
		header {
			opacity: 0;
			transition: opacity 0.3s ease-in-out ;
		}
		.description {
			transition: all 0.3s ease-in-out ;
			bottom: 0;
			max-height: 100%;
			opacity: 1;
		}
	}

	body.layout-sm-tall & {
		display: flex;
		justify-content: space-between;
		width: 100%;
		// padding-top: $u*8;
		overflow: visible;
		&:before,
		&:after {
			display: none;
		}

		header {
			padding: $u*2 $u*1.5;
			height: auto;
			align-items: center;
			justify-content: flex-end;
			bottom: auto;
			top: 100%;
			h2 {
				display: none;
			}

			nav {
				justify-content: center;
				width: auto;
				margin: 0;

				.show-description {
					display: none;
				}

				span {
					text-align: center;
					display: block;
					padding: 0;
					width: 100%;
				}

				.previous-image,
				.next-image {
					width: $u*5;
					height: $u*4;
					flex-shrink: 0;
				}

			}
		}
		
		.images {
			aspect-ratio: 3 / 2;
			width: 100%;
		}

		.description {
			position: fixed;
			opacity: 1;
			z-index: 50;
			max-height: none;
			padding: $u*2 0 $u*2;
			bottom: 0;
			top: calc(66.6vw + #{$u*8});
			overflow: hidden;
			h2, 
			h3,
			p {
				padding-left: $u*2;
				padding-right: $u*2;
			}
			h2 {
				font-size: $u*2;
			}
			h3 {
				font-size: $u*1.75;
			}
			p {
				font-size: $u*2;
				line-height: $u*2.5;
			}

			& > div {
				max-width: none;
				height: calc(100% - #{$u*4});
				overflow-y: auto;
				& > *:first-child {
					margin-top: 0;
				}
				& > *:last-child {
					margin-bottom: $u*3;
				}
			}

			.hide-description {
				display: none;
			}
		}
	}

	body.layout-sm-wide & {
		display: flex;
		justify-content: space-between;
		height: 100%;

		header {
			padding: 0 $u*1.5;
			height: 0;
			align-items: flex-end;
			h2 {
				max-width: $u*12;
				margin-bottom: $u*4;
			}

			nav {
				flex-wrap: wrap;
				justify-content: center;
				width: $u*9;
				margin-bottom: $u*0.5;

				.show-description {
					position: fixed;
					bottom: $u*1.25;
					left: $u*1.5;
					width: $u*12.5;
					padding: $u*8 0 0;
					height: auto;
					font-size: $u*2;
					text-align: left;
					svg {
						display: none;
					}
				}

				span {
					text-align: center;
					display: block;
					padding: 0;
					order: 2;
					width: 100%;
				}

				.previous-image,
				.next-image {
					width: $u*4.5;
					height: $u*3.5;
				}

				.previous-image {
					order: 3;
				}

				.next-image {
					order: 4;
				}
			}
		}
		
		.images {
			display: flex;
			height: 100%;
			flex-shrink: 1;
			
			&:before,
			&:after {
				content: '';
				display: block;
				position: relative;
				flex-grow: 10;
				flex-shrink: 0;
				min-width: $u*14;
			}
			ul {
				aspect-ratio: 3 / 2;
				position: relative;
				width: auto;
				flex-shrink: 1;
				max-width: calc(100% - #{$u*26});
			}
			li > * {
				background: transparent;
				img {
					width: 100%;
				}
			}
		}

		.description {
			z-index: 1500;
			padding: $u*2 $u*2 $u*2;
			h2 {
				font-size: $u*2;
			}
			h3 {
				font-size: $u*1.75;
			}
			p {
				font-size: $u*1.75;
				line-height: $u*2.25;
			}

			& > div > * {
				max-width: none;
			}

			.hide-description {
				top: $u*1.5;
				right: $u*2.25;
			}
		}
	}

	body.layout-md {
		header {
			nav {
				margin-top: 0;
			}
			button.show-description {
				text-align: center;
				font-size: $u*1.8;
				line-height: $u*3;
				transform: none;
				margin: 0 $u 0 0;
				&:after {
					display: none;
				}
			}
		}

	}
	body.layout-lg &,
	body.layout-xl & {
		header {
			height: $u*6;
			padding: $u 0 $u*1;
			h2 {
				font-size: $u*1.8;
				float: left;
				text-align: left;
			}
			span {
				padding: 0;
				color: $light-gray;
			}
			nav {
				margin-top: 0;
				text-align: right;
			}
			button.show-description {
				text-align: center;
				font-size: $u*1.8;
				line-height: $u*3;
				transform: none;
				width: auto;
				svg {
					display: none;
				}

			}
		}
		.description {
			padding: $u*5 $u*5 $u*9;
			h2 {
				font-size: $u*2;
				margin-bottom: $u*0.5;
			}
			h3 {
				margin-bottom: $u*2;
			}
			p {
				//font-size: $u*1.5;
			}
			.hide-description {
				top: $u*4;
				right: $u*4;
			}
		}
	}
}