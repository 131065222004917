#main-nav {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
  z-index: 1000;
	flex-shrink: 0;
	.menu-button {
		display: block;
		position: relative;
		width: $u*4;
		height: $u*4;
		font-size: 0;
		border: none;
		background: transparent !important;
		-webkit-tap-highlight-color: transparent;
		-webkit-user-select: none;
		user-select: none;
		color: $gray;
		padding: 0;
		outline: none;
		font-weight: 100;
		z-index: 1200;
		appearance: none;
		line-height: $u*2;

    span,
    &:before,
    &:after {
      display: block;
      position: absolute;
      content: '';
      left: $u*0.5;
      right: $u*0.5;
      height: 2px;
      background: currentColor;
    }
    span {
      top: calc(50% - 1px);
    }
    &:before {
      top: $u*0.85;
    }
    &:after {
      bottom: $u*0.85;
    }

		&:hover {
			cursor: pointer;
			color: $highlight;
		}
	}
	a {
		color: $gray;
		text-transform: uppercase;
		font-size: $u*2.0;
		line-height: $u*2.75;
		white-space: nowrap;
		&:hover,
		&:active,
		&.active, {
			color: $highlight;
		}
	}
	li.current_page_item > a,
	li.current-menu-item > a {
		color: $highlight;
	}
	#home-link {
		display: block;
		background-image: url(../images/tyler-engle-logo.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: left top;
		height: $u*5;
		font-size: 0;
		width: $u*9;
		z-index: 5;
	}
  .overlay-shade {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
    transition: height 0s linear 0.3s, opacity 0.3s ease-in-out 0s;
  }
	&.show {
		& > ul,
		& > .menu > ul {
			max-height: 400px;
			opacity: 1;
			transition: max-height 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.3s;
		}
	}
	& > ul,
	& > .menu > ul {
		display: block;
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		transition: max-height 0.3s ease-in-out 0.3s, opacity 0.2s ease-in-out 0s;
		@include clearfix;
		& > li {
			padding: $u*1 0;
			& > a {
				font-weight: 500;
				color: lighten($gray, 10%);
			}
			& > ul {
				z-index: 100;
				& > li {
					padding: 0 0 0 $u*1.5;
					a {
						text-transform: capitalize;
					}
				}
			}
			&.current-menu-ancestor,
			&.current-menu-item {
				& > a {
					color: $highlight;
				}
			}
		}
	}

  body.layout-sm-wide &,
  body.layout-sm-tall &,
	body.layout-md & {
    background: white;
		padding: 0 $u*1.5;
		height: $u*8;
		& + #content-wrapper {
			min-height: calc(100% - #{$u*8})
		}
    #menu-main-menu {
			position: absolute;
			top: 0;
			right: 0;
      z-index: 500;
			background: white;
			padding: 0 $u*3;
			& > .menu-item:first-child {
				margin-top: $u*1;
			}
			& > .menu-item:last-child {
				margin-bottom: $u*1;
			}
    }
    &.show {
      // position: absolute;
      #menu-main-menu {
        max-height: 420px;
      }
      .overlay-shade {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.4);
        z-index: 10;
        transition: height 0s linear, opacity 0.5s ease-in-out 0.2s;
      }
    }
  }

  body.layout-sm-tall & {
    height: $u*8;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
    #home-link {
      height: $u*5;
      font-size: 0;
      width: $u*9.5;
    }
		#menu-main-menu {
			width: $u*30;
      a {
        font-size: $u*2.25;
        line-height: $u*3.5;
      }
		}
	}

  body.layout-sm-wide.home &,
  body.layout-sm-wide.single-project & {
    position: fixed;
    height: 0;
		top: 0;
		left: 0;
		right: 0;
		#home-link {
			position: absolute;
			top: $u*1.5;
			left: $u*1.5;
			height: $u*5;
			width: $u*9;
		}
		.menu-button {
			position: absolute;
			top: $u*1.0;
			right: $u*1.5;
		}
  }

	body.layout-sm-wide &,
	body.layout-md & {
		#menu-main-menu {
			width: $u*26;
      a {
        font-size: $u*2.0;
        line-height: $u*3.0;
      }
		}
	}

	body.layout-md & {
		position: relative;
	}

	body.layout-lg &,
	body.layout-xl & {
		height: $u*12;
		z-index: 2;
		.menu-button {
			display: none;
		}
		#home-link {
			height: $u*7.0;
			width: $u*14;
			background-size: $u*11.65;
		}
		#menu-main-menu {
			margin-top: $u*6;
		}
		& > ul,
		& > .menu > ul {
			text-align: right;
			max-height: none;
			overflow: visible;
			opacity: 1;
			& > li {
				display: inline-block;
				position: relative;
				margin-left: $u*6;
				padding: 0;

				&.current-menu-ancestor,
				&.current-menu-item,
				&:hover {
					& > ul {
						opacity: 1;
					}
				}
				a {
					font-size: $u*1.6;
					line-height: $u*2;
				}
				& > ul {
					position: absolute;
					bottom: $u*2;
					left: -$u*0.85;
					text-align: left;
					padding: 0 $u*1 $u*0.5;
					opacity: 0;
					transition: opacity 0.2s ease-in-out;
					& > li {
						display: block;
						padding: 0;
						line-height: $u*1.5;
					}
					a {
						font-weight: 300;
						font-size: $u*1.4;
						text-transform: capitalize;
					}
				}
			}
		}
	}

	body.layout-xl & {
		padding-top: $u*2;
		height: $u*14;
		#home-link {
			height: $u*10;
			width: $u*20;
			margin-bottom: $u*1.5;
			background-size: contain;
		}
		#menu-main-menu {
			margin-top: 0;
			padding-top: $u*7;
		}
		& > ul,
		& > .menu > ul {
			bottom: $u*1.75;
			& > li {
				&.current-menu-ancestor,
				&.current-menu-item {
					& > ul {
					}
				}
				a {
					font-size: $u*1.8;
				}
				& > a {
				}
				& > ul {
					bottom: $u*2.0;
					& > li {
						line-height: $u*2.5;
					}
					a {
						font-size: $u*1.6;
					}
				}
			}
		}
	}
}