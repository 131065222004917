$background: #ffffff;
$gray: #707270;
$xlight-gray: #eeeeef;
$light-gray: #8f9194;
$highlight: #1488b2;

$u: 8px;

$breakpoint-small: 480px;
$breakpoint-medium: 820px;
$breakpoint-large: 1024px;
$breakpoint-x-large: 1488px;

@import "_reset.scss";
@import "_functions.scss";
@import "_layout.scss";
@import "_nav.scss";
@import "_home.scss";
@import "_projects-list.scss";
@import "_project-detail.scss";
@import "_office.scss";
@import "_recognition.scss";
@import "_contact.scss";