#home-carousel {
	z-index: 10;
	height: 100%;
	overflow: hidden;
	padding: 0;

	& > div {
		position: relative;
		ul,
		img,
		li,
		a,
		span {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			// bottom: 0;
			left: 0;
			aspect-ratio: 3 / 2;
		}
	}

	li {
		overflow: hidden;
		opacity: 0;
		z-index: 1;
		transition: opacity 2s ease-in-out 0.5s;
		&.show {
			opacity: 1;
			z-index: 2;
			transition: opacity 2s ease-in-out;
		}
	}
	a {
		span {
			max-height: 100%;
		}
	}
	img {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		// height: 100%;
		// object-fit: contain;
	}

	body.layout-sm-tall & {
		bottom: auto;
		padding: 62.5% 0 0;
		img {
			object-fit: cover;
		}
	}

	body.layout-sm-wide & {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		& > div {
			width: calc(100vw - #{$u * 20});
			max-width: 160vh;
			aspect-ratio: 16 / 9;
		}
	}

	body.layout-sm-tall &,
	body.layout-md &,
	body.layout-lg &,
	body.layout-xl & {
		& > div {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			// bottom: 0;
			left: 0;
		}
	}

	body.layout-md & {
		& > div {
			// display: block;
			// width: 100%;
			aspect-ratio: 16 / 9;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#home-mobile-content {
	display: none;
	body.layout-sm-tall & {
		display: block;
		padding: $u * 2;
		animation-name: fadeIn;
		animation-delay: 0.5s;
		animation-duration: 1s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		opacity: 0;
		p {
			font-size: $u * 2;
			line-height: $u * 3;
		}
	}
}
