#contact {
	padding: $u*3 $u*1.5;
	p,
	a {
		color: $gray;
		font-size: $u*2;
		line-height: $u*3;
	}
	a:hover {
		color: $highlight;
	}
	& > div > * {
		margin: $u*3 0;
	}
	.social {
		display: block;
		font-size: 0;
		li {
			display: inline-block;
			vertical-align: middle;
		}
		a {
			display: block;
			background-color: $gray;
			background-size: 100%;
			background-position: center;
			text-indent: -1000px;
			width: $u*4;
			height: $u*4;
			margin-right: $u;
			transition: background-color .3s ease-in-out;
			&:hover {
				background-color: $highlight;
				transition: background-color 0s;
			}
		}
		.fb {
			background-image: url('../images/social/fb.png');
		}
		.inst {
			background-image: url('../images/social/inst.png');
		}
		.ico {
			background-image: url('../images/social/ico.png');
		}
		.hz {
			background-image: url('../images/social/hz.png');
		}
		span {
			display: inline-block;
			vertical-align: middle;
			font-size: $u*2;
			line-height: $u*4;
			margin-left: $u*1.5;
			text-transform: uppercase;
			color: $highlight;
		}
	}

	body.layout-sm-tall &,
	body.layout-sm-wide & {
		font-size: $u*2;
		line-height: $u*3;
		& > div {
			& > *:last-child {
				position: fixed;
				margin: 0;
				a {
					display: block;
				}
				img {
					display: block;
				}
			}
		}
	}

	body.layout-sm-tall & {
		padding: $u*1.5;
		& > div {
			& > *:first-child {
				margin-top: 0;
			}
			& > *:last-child {
				position: fixed;
				bottom: $u*1.5;
				left: $u*1.5;
				margin: 0;
			}
		}
	}

	body.layout-sm-wide & {
		padding: $u*1.5 $u*2;
		& > div {
			& > *:first-child {
				margin-top: 0;
			}
			& > *:last-child {
				position: fixed;
				bottom: $u*2;
				right: $u*2;
				margin: 0;
			}
			& > *:last-child {
				position: fixed;
				bottom: $u*2;
				right: $u*2;
				margin: 0;
			}
			.social {
				position: fixed;
				bottom: $u*2;
				left: $u*2;
				margin: 0;
			}
		}
	}


}