#office {
	position: relative;
	max-width: calc(100vw - #{$u*3});
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	padding-top: $u*1.5;
	body.layout-sm-tall & {
		max-width: calc(100vw - #{$u*6});
		flex-direction: column;
		padding-top: 0;
	}
	body.layout-sm-wide & {
		max-width: calc(100% - #{$u*4})
	}
}

#office-header {
	margin: $u*2 auto $u*3;
	img {
		display: block;
		position: relative;
		width: auto;
		height: auto;
		max-height: 40vh;
		max-width: 100%;
		margin: 0 auto;
	}
	h2 {
		display: none;
	}
	body.layout-sm-tall & {
		padding-top: 0;
	}

	body.layout-sm-wide & {
		margin: 0;
		position: relative;
		width: 33.3%;
		max-width: $u*20;
		flex-shrink: 0;
		img {
			max-height: none;
			width: 100%;
			margin-left: 0;
		}
	}
	body.layout-md &,
	body.layout-lg &,
	body.layout-xl & {
		position: relative;
		margin: 0;
		max-width: none;
		display: block;

		img {
			margin: 0;
			max-width: $u*30;
		}
	}
}
#office-content {
	margin: 0 0 0 $u*3;	
	max-width: 600px;
	h2, h3 {
		text-transform: none;
		font-size: $u*2.5;
		line-height: $u*2.5;
		margin-bottom: $u*1;
		text-transform: capitalize;
		font-weight: 500;
	}
	h2 + h3 {
		text-transform: none;
	}
	h2 + h6 {
		color: $light-gray;
		text-transform: none;
		margin: -$u 0 $u*3;
	}
	p {
		font-size: $u*2;
		line-height: $u*2.5;
		margin: $u*1 0 $u*3;
	}
	body.layout-sm-tall & {
		margin-left: 0;
	}
	body.layout-sm-wide & {
		p {
			font-size: $u*1.75;
			line-height: $u*2.25;
			margin: 0 0 $u*1.5;
		}
	}
}