body.post-type-archive-project {
	transition: max-width 0.8s ease-in-out;
	&.grid-ready {
		max-width: 1880px;
		#body-wrapper {
			max-width: 1880px;
		}
	}
}


#projects-list {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	padding-top: $u*3;
	padding-bottom: $u*6;
	li {
		display: block;
		width: 33.33%;
	}
	a {
		display: block;
		padding: 0 $u*0.25;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		&.faded {
			opacity: 0.5;
		}
		&.show {
			opacity: 1;
			&:hover {
				.img:after {
					opacity: 0.3;
					transition: opacity 0.1s ease-in-out;
				}
			}
		}

	}
	.img {
		padding: 0 0 66.3%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: $light-gray;
		position: relative;
		img {
			display: none;
		}
		&:after {
			display: block;
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: white;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
		}
	}
	span {
		display: block;
		font-size: $u*2;
		line-height: $u*2.5;
		padding: $u*0.25 0 $u*1.5;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}
}


body.layout-sm-tall {
	#projects-list {
		padding-top: 0;
		li { 
			width: 100%;
			a {
				padding: 0;
			}
			span {
				padding: $u*0.75 $u*1.5 $u*2.25;
			}
		}
	}
}

body.layout-sm-wide {
	#projects-list {
		padding-top: 0;
		li { 
			width: 33.3%;
		}
		a {
			padding: 0 3px $u*1.5;
		}
		.img {
			width: 100%;
		}
		span {
			display: block;
			font-size: $u*1.75;
			line-height: $u*2;
			padding: $u*0.5 0 0;
		}
	}
}

body.layout-md,
body.layout-lg,
body.layout-xl {
	#projects-list {
		max-width: calc(100vw - #{$u*1});
		margin: 0 auto;

		span {
			font-size: $u*1.75;
		}
	}
}

body.layout-xl {
	#projects-list {
		margin-left: -$u*0.25;
		margin-right: -$u*0.25;
		li {
			width: 33.3%;
			padding: 0;
		}
		span {
			font-size: $u*1.75;
		}
	}
}