#awards {
	h2 {
		font-size: $u*2;
		line-height: $u*3;
		padding: 0 0 $u*3;
	}
	h3,
	p {
		font-size: $u*1.75;
		line-height: $u*2.5;
	}
	ul {
		// padding-top: $u*3;
		padding: $u*1.5 0;
		max-width: calc(100vw - #{$u*3});
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	}
	li {
		padding-bottom: $u*2;
		display: flex;
		align-items: center;
		h3 {
			text-transform: none;
			margin-bottom: $u*0.5;
			font-weight: 500;
		}
		.image {
			width: 35%;
			max-width: 200px;
			flex-shrink: 0;
			flex-grow: 0;
			background-color: $xlight-gray;
			img {
				display: block;
				width: 100%;
			}
		}
		a.image {
			transition: opacity 0.1s ease-in-out;
			&:hover {
				opacity: 0.66;
			}
		}
		.content {
			flex-grow: 2;
			margin-left: $u*1.5;
		}
		.download {
			display: inline-block;
			margin-top: $u;
		}
	}
	body.layout-sm-tall & {
		padding-top: 0;
		ul {
			padding-top: 0;
		}
		li {
			width: 100%;
			.image {
				max-width: 160px;
			}
		}
	}
	body.layout-md &,
	body.layout-lg &,
	body.layout-xl & {
		h2 {
			padding-top: $u*8;
			padding: 0 0 $u*4;
		}
		ul {
			position: relative;
		}
		li {
			width: 50%;
			padding: 0 $u*2 $u*3 0;
		}
	}
}