/* Breakpoints */
@mixin breakpoint($point) {
  @if $point == small {
    @media (min-width: $breakpoint-small) { @content; }
  }
  @if $point == medium {
    @media (min-width: $breakpoint-medium) { @content; }
  }
  @if $point == large {
    @media (min-width: $breakpoint-large) { @content; }
  }
  @if $point == x-large {
    @media (min-width: $breakpoint-x-large)  { @content; }
  }
}

// http://www.joshfry.me/blog/2013/05/13/omega-reset-for-bourbon-neat
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin vert-center() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin clearfix() {
  clear: both;
  &:after {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    content: '';
    clear: both;
  }
}