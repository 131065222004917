html,
body {
	height: 100%;
	min-height: 100%;
}
html {
	scrollbar-width: thin;
	scrollbar-gutter: stable;
}
body {
	font-family: "Helvetica Light", "Helveticaneue Light", "Helvetica", "Calibri Light", "Helveticaneue", "Gill Sans",
		"Myriad Pro", Arial, "Lucida Grande", sans-serif;
	font-style: normal;
	font-weight: 100;
	font-size: $u * 1.6;
	letter-spacing: 0.03rem;
	line-height: $u * 2;
	display: block;
	position: relative;
	//background: grey;
	padding: 0;
	max-width: $breakpoint-x-large;
	margin: 0 auto;
}
p,
li,
a {
	color: $light-gray;
}
h1,
h2,
h3 {
	color: $gray;
	text-transform: uppercase;
}
a {
	text-decoration: none;
	transition: color 0.6s;
	&:hover,
	&:active,
	&.active {
		color: $highlight;
		transition: color 0s;
	}
}
#body-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	position: relative;
	margin: 0 auto;
}
#content-wrapper {
	flex-grow: 5;
	position: relative;
	& > * {
		position: relative;
	}
}

body.layout-sm-tall {
	#content-wrapper {
		padding-top: $u * 8;
	}
}

body.layout-sm-wide.home,
body.layout-sm-wide.single-project {
	#body-wrapper,
	#content-wrapper {
		height: 100%;
		flex-grow: 100;
	}
}

body.layout-md {
	#body-wrapper {
		justify-content: flex-start;
		height: 100%;
	}
}

body.layout-lg,
body.layout-xl {
	#body-wrapper {
		position: relative;
		padding: 0;
		width: 100%;
		height: 100%;
		max-width: 124vmin;
		margin: 0 auto;
	}
	#content-wrapper {
		display: inline-block;
		position: relative;
		width: 100%;
		max-width: 100%;
	}
}

body.layout-lg {
	#body-wrapper {
		width: calc(100% - #{$u * 6});
		max-width: 112vmin;
		min-width: 720px;
	}
}

body.layout-xl {
	#body-wrapper {
		width: calc(100% - #{$u * 6});
		max-width: 106vmin;
		min-width: 720px;
		margin: 0 auto;
	}
}

body.layout-xl.archive,
body.layout-xl.home,
body.layout-xl.single {
	#body-wrapper {
		max-width: 120vmin;
	}
}

.bw {
	filter: grayscale(100%) blur(2px);
}
